<template>
  <div>
    <transition name="fade-up-down">
      <div v-if="!$isReadOnly()" class="flex flex-wrap gap-3">
        <edit-order-change-address></edit-order-change-address>
        <edit-order-change-time-slot></edit-order-change-time-slot>
      </div>
    </transition>
  </div>
</template>

<script>
import EditOrderChangeAddress from "./EditOrderChangeAddress";
import EditOrderChangeTimeSlot from "./EditOrderChangeTimeSlot";

export default {
  name: "EditOrderDeliveryDetails",
  components: {EditOrderChangeTimeSlot, EditOrderChangeAddress}
}
</script>

