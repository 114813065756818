<template>
  <div>
    <button-element size="xsmall" type="warning" @click="onEditButtonClick">
      <div class="flex items-center gap-x-2">
        <fa-icon icon="edit"/>
        Créneau
      </div>
    </button-element>

    <modal ref="changeTimeSlotModal" title="Changement créneau horaire">
      <time-slot-selection
        v-if="showSelectInput"
        v-model="selectedTimeSlotId"
        :zone-id="getDeliveryAddress.zoneId"
        used-in-modal
      />

      <template #footer>
        <div class="flex justify-between gap-x-16">
          <button-element :disabled="selectedTimeSlotId === null" type="primary"
                          @click="changeDeliveryTimeSlot">
            <fa-icon class="mr-2" icon="check"></fa-icon>
            Confirmer
          </button-element>

          <button-element type="light" @click="closeModal">
            <fa-icon class="mr-2" icon="times"></fa-icon>
            Annuler
          </button-element>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import ButtonElement from "../../../../elements/buttons/ButtonElement";
import Modal from "../../../../elements/modals/Modal";
import OrderRepository from "../../../../../repositories/OrderRepository";
import TimeSlotSelection from "../../../../global/TimeSlots/TimeSlotSelection";

export default {
  name: "EditOrderChangeAddress",
  components: {TimeSlotSelection, Modal, ButtonElement},
  data: () => ({
    showSelectInput: false,
    selectedTimeSlotId: null,
  }),
  methods: {
    onEditButtonClick() {
      this.$refs.changeTimeSlotModal.openModal();
      this.showSelectInput = true;
    },
    closeModal() {
      this.$refs.changeTimeSlotModal.closeModal();
      this.selectedTimeSlotId = null;
    },
    changeDeliveryTimeSlot() {
      if (!this.selectedTimeSlotId)
        return false;

      OrderRepository.changeDeliveryTimeSlot(this.getOrderId, this.selectedTimeSlotId).then(() => {
        this.$sendSuccessToast('Créneau horaire changé.');
        this.$store.dispatch('order/refreshOrder').then(() => {
          this.closeModal();
        });
      });
    }
  },
  computed: {
    getOrderId() {
      return this.$store.getters['order/getOrderId'];
    },
    getDeliveryAddress() {
      return this.$store.getters['order/getDeliveryAddress'];
    }
  },
}
</script>

